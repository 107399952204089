// @ts-ignore
import { ref } from 'vue'

import { HdExaminationItem } from '@/apis/entity/HdExaminationItem'
import { getExaminationItemList, getExaminationList } from '@/apis/lambda/hdexamination'
import { FlyHttp } from '@/utils/Http'
import { HdEyeexamination } from '@/apis/entity/HdExamination'
import { getAttachList } from '@/apis/lambda/attach'
import { Attach } from '@/apis/entity/Attach'
import { ATTACH_HOST_URL } from '@/apis/constants/Constants'
import { Report } from '@/apis/entity/Report'
import { getReport } from '@/apis/lambda/report'

export function useExamination() {
  const examinationItemMap = {
    '003002': '眼压检查',
    '003003': '验光检查',
    '003005': 'SLO',
    '003006': '眼表综合分析',
    '003007': 'IOLmaster',
    '003008': 'OCT',
    '003009': '眼前段检查'
  }

  const reportData = ref<Report>()
  const examinationItemList = ref<HdExaminationItem[]>([])
  const examinationList = ref<HdEyeexamination[]>([])
  const attachList = ref<Attach[]>([])

  const initExaminationData = (examinationSheetId) => {
    FlyHttp
      .request(getReport, { options: { examinationSheetId } })
      .then(resp => {
        reportData.value = resp.data
      })

    FlyHttp
      .request(getExaminationItemList, { options: { examinationSheetId } })
      .then(resp => {
        examinationItemList.value = resp.data
      })
    
    FlyHttp
      .request(getExaminationList, { options: { examinationSheetId } })
      .then(resp => {
        examinationList.value = resp.data
      })
    
    FlyHttp
      .request(getAttachList, { options: { parentId: examinationSheetId } })
      .then(resp => {
        attachList.value = resp.data
      })
  }

  const parseExaminationItemName = (code) => {
    return examinationItemMap[code]
  }

  /**
   * 获取检查项值
   * @param code 检查项编码
   * @param data 相关检查数据
   * @returns 显示值
   */
  const getExaminationValue = (code, data: HdEyeexamination[]) => {
    const item = data.find(x => x.indexCode === code)
    return item ? item.displayString : ''
  }

  /**
   * 获取附件数据
   * @param code 附件数据编码
   * @param data 相关检查报告附件数据
   * @returns 
   */
  const getExaminationAttachList = (code, data: Attach[]) => {
    const list = data.filter(x => x.attchType === code)
    return list
  }

  /**
   * 获取附件项目上
   * @param code 附件数据编码
   * @param data 相关检查报告筛选附件数据
   * @returns 
   */
  const getExaminationAttachData = (code, data: Attach[]) => {
    const list = getExaminationAttachList(code, data)
    if (!(list.length > 0)) {
      return null
    }
    const item = list.find(x => x.attchType === code)
    return item
  }

  /**
   * 获取附件项目上
   * @param code 附件数据编码
   * @param data 相关检查报告筛选附件数据
   * @returns 
   */
   const getExaminationAttachUrl = (code, data: Attach[]) => {
     const item = getExaminationAttachData(code, data)
    return item ? ATTACH_HOST_URL + '/' + item.attachPath : ''
   }
  
    /**
   * 获取附件项目上
   * @param code 附件数据编码
   * @param data 相关检查报告筛选附件数据
   * @returns 
   */
  const getExaminationAttachUrls = (code, data: Attach[]) => {
    const urls = getExaminationAttachList(code, data).map(x => ATTACH_HOST_URL + '/' + x.attachPath)
    return urls
   }

  return {
    reportData,
    examinationItemList,
    examinationList,
    attachList,
    initExaminationData,
    parseExaminationItemName,
    getExaminationValue,
    getExaminationAttachList,
    getExaminationAttachData,
    getExaminationAttachUrl,
    getExaminationAttachUrls
  }
}