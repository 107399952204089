const ADMIN_HOST_URL = 'http://gjjc.eyescare.cn:8080'
const ATTACH_HOST_URL = ADMIN_HOST_URL + '/highmyopia'

const SMS_HOST_URL = ADMIN_HOST_URL + '/highmyopia'
const SMS_SEND_URL = SMS_HOST_URL + '/api/smscode/apply.do'
const SMS_VALIDATE_URL = SMS_HOST_URL + '/api/smscode/checkCode.do'

export {
  ADMIN_HOST_URL,
  ATTACH_HOST_URL,
  SMS_SEND_URL,
  SMS_VALIDATE_URL
}