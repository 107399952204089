// @ts-ignore
import {  } from 'vue'

export function useDictionary() {
  const dictType = {
    gendor: 'Gendor'
  }

  const dictList = [
    { type: dictType.gendor, dictCode: '0', dictName: '男' },
    { type: dictType.gendor, dictCode: '1', dictName: '女' },
  ]
  
  const getDict = (type: string, code: string) => {
    return dictList.find(x => x.type === type && x.dictCode === code + '')
  }

  return { dictType, getDict }
}