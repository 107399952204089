// @ts-ignore
import { ref, onMounted } from 'vue'

import { Patient } from '@/apis/entity/Patient'
import { useUserStore } from '@/store/modules/user';
import { isNullAndUnDef } from '@/apis/utils';

export function usePatientInfo() {
  const userSotore = useUserStore()

  const patient = ref<Patient>();

  onMounted(() => {
    patient.value = userSotore.loginInfo
  })

  // 计算年龄
const getAge = (birthday) => {
  if (isNullAndUnDef(birthday)) {
    return
  }
  // 出生时间 毫秒
  const birthDayTime = new Date(birthday).getTime()
  // 当前时间 毫秒
  const nowTime = new Date().getTime()
  // 一年毫秒数(365 * 86400000 = 31536000000)
  return Math.floor((nowTime - birthDayTime) / 31536000000)
}

  return { patient, getAge }
}