import { request } from '@midwayjs/hooks/request'


export  function getExaminationItemList (...args) {
  return request({
    url: '/api/hdexamination/getExaminationItemList',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "lambda-hdexamination-getexaminationitemlist",
  "gateway": "http",
  "functionGroup": "highmyopia-report"
}
  })
}

export  function getExaminationList (...args) {
  return request({
    url: '/api/hdexamination/getExaminationList',
    method: 'POST',
    data: {
      args: args,
    },
    meta: {
  "functionName": "lambda-hdexamination-getexaminationlist",
  "gateway": "http",
  "functionGroup": "highmyopia-report"
}
  })
}

